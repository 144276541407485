import Layout from "../components/layout"
import React from "react"
import { graphql } from "gatsby"

const PublicationsPage = ({location, data}) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  return (
    <Layout location={location} title="Publications">
      <h1>Publications</h1>
      <p>Coming soon.</p>
      {/*<p>*/}
      {/*  Here are some of the research papers I co-authored while working as a research assistant at the*/}
      {/*  <a href="https://www.dsg.tuwien.ac.at/">Distributed Systems Group</a> at <a href="https://www.tuwien.at/">TU Wien</a>.*/}
      {/*</p>*/}
      {/*<ol>*/}
      {/*  { data.allReference.edges.map(({node}) => <Reference key={node.key} reference={node}/>) }*/}
      {/*</ol>*/}
    </Layout>
  );
}

export default PublicationsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allReference(sort: {fields: date, order: DESC}) {
      edges {
        node {
          author
          year
          title
          paper
          entry_type
          authors
          date
          booktitle
          journal
          key
        }
      }
    }
  }
`
